import React from 'react';
import './AboutUs.css';
import aboutUsImage from '../../../Assets/Images/internship.jpg';

const AboutUsComp = () => {
  return (
    <>
      <h2 className="page-title">About Us</h2>
      <div className="about-us-container">
        <div className="about-us-image">
          <img src={aboutUsImage} alt="About Us" />
        </div>
        <div className="about-us-content">
          <p className="about-us-text">
            RuhiTech Solutions specializes in providing comprehensive training
            programs in coding and software testing, equipping individuals with
            essential skills for various software technologies. Our curriculum
            covers a wide range of topics, from programming languages to
            advanced testing methodologies.
          </p>
          <p className="about-us-text">
            In addition to training, RuhiTech Solutions offers job consulting
            services, guiding students through job placements and career
            development. Our expert team provides personalized support to ensure
            students are well-prepared for the job market. With a focus on
            practical skills and industry relevance, RuhiTech Solutions aims to
            bridge the gap between education and employment.
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutUsComp;
